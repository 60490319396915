
import {computed, defineComponent, onMounted, ref, unref} from 'vue'
import {login} from '../../../../global/js/magento-api-library/auth/login'
import loading from '../../composables/loaders/loading'
import LoginForm from './LoginForm.vue'
import {getAndSetCustomer} from '../../composables/user/checkIfUserLoggedIn'
import checkIfUserLoggedIn from '../../composables/user/checkIfUserLoggedIn'
import {enrollInProduct} from '../../../../global/js/utils/enrollmentHelper'
import modal from '../modal/ModalPopUp.vue'
import {SlimProduct} from "../../../../global/js/utils/types/SlimProduct";

export default defineComponent({
  name: 'Login',
  components: {
    LoginForm,
    modal,
  },
  setup() {
    const loginFailed = ref(false)
    const errorMessage = ref('An error has occurred.')
    const isModalVisible = ref(false)

    onMounted(async (): Promise<void> => {
      if (checkIfUserLoggedIn()) {
        await getAndSetCustomer()
        window.location.href = '/'
      }
    })

    const userLoggedOut = computed(() => {
      let logout = new URLSearchParams(location.search)
      return !!logout.get('logout')
    })

    const canGoBack = (): boolean => {
      const referrer = document.referrer
      return referrer !== '' && referrer.indexOf(location.protocol + '//' + location.host) === 0
    }

    async function handleInput(formValues: { email: string, password: string }) {
      loading(true)
      loginFailed.value = false
      const successfulLogin = await login(formValues.email, formValues.password)
      if (successfulLogin) {
        let returnUrl = localStorage.getItem('returnUrl')
        const product = JSON.parse(localStorage.getItem('product') ?? '{}')
        await getAndSetCustomer()

        if (returnUrl !== null) {
          if (product.rlms_id !== null) {
            try {
              const enrollResult = await enrollInProduct(product)
              if (!enrollResult) {throw 'enrollment failed'}
            } catch (e: unknown) {
              console.log(e)
              // send them back to where they came, so they can try again
              localStorage.setItem('sso_url', returnUrl)
            }
            let ssoUrl = localStorage.getItem('sso_url')
            if (ssoUrl !== null) {
              returnUrl = ssoUrl
            }
          }
          loading(false)
          const ssoTab = window.open(returnUrl, product.value?.sku ?? 'rlpNewTab')
          localStorage.removeItem('returnUrl')
          localStorage.removeItem('product')
          if (!ssoTab) {
            //new tab blocked
            errorMessage.value = `Something went wrong while displaying this webpage
              - <a href="${returnUrl}">please click here</a> to continue to this activity.`
            isModalVisible.value = true
          }
          localStorage.removeItem('sso_url')
        } else {
          loading(false)
        }

        if (canGoBack()) {
          window.location.assign(document.referrer)
        } else {
          window.location.assign('/')
        }
      }
      loading(false)
      loginFailed.value = !successfulLogin
    }

    loading(false)
    return {
      loginFailed,
      handleInput,
      isModalVisible,
      userLoggedOut,
    }
  },
})
