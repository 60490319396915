import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

import { store as courses, State as CoursesState } from './modules/courses/index'
import { store as ui, State as UIState } from './modules/ui/index'

export interface RootState {
  courses: CoursesState
  ui: UIState
}

export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore<RootState>({
  modules: {
    courses,
    ui,
  },
})

export const useStore = (): Store<RootState> => baseUseStore(key)
