
import { defineComponent, reactive, watch } from 'vue'
import { useField, useForm } from 'vee-validate'
import { errorMessage } from '../../store/modules/ui/errorMessage'

export default defineComponent( {
  name: 'LoginForm',
  props: {
    failedLogin: Boolean,
  },
  emits: {
    submitAuth: null,
  },
  setup(props, { emit }) {
    const { meta: formMeta, handleSubmit, setFieldError } = useForm()
    const emailField = reactive(useField('email', { email: true, required: 'Email'}))
    const passwordField = reactive(useField('password', { required: 'Password' }))

    const submitForm = handleSubmit((formValues) => {
      emit('submitAuth', {
        email: formValues.email.toLowerCase(),
        password: formValues.password,
      })
    })

    watch(
      () => props.failedLogin,
      () => {
        if(props.failedLogin) {
          setFieldError('email', 'Invalid login')
          setFieldError('password', 'Invalid login')
        }
      },
    )

    function validateForm(event: KeyboardEvent) {
      if (event.target instanceof Element && event.target.id === 'user_email') {
        if (passwordField.meta.validated) {
          passwordField.validate()
        }
      } else {
        if (emailField.meta.validated) {
          emailField.validate()
        }
      }
    }

    return {
      emailField,
      errorMessage,
      passwordField,
      submitForm,
      setFieldError,
      formMeta,
      validateForm,
    }
  },
})
