import type Customer from '../magento-api-library/types/Customer'
import {sendEnrollment} from '../n8n-api-library/sendEnrollmentRequest'
import getRLPSSOToken from './getRLPSSOToken'
import getStoreName from './getStoreName'
import getBearerToken from './getBearerToken'
import {SlimProduct} from './types/SlimProduct'
import {getAndSetCustomer} from '../../../freecme/js/composables/user/checkIfUserLoggedIn'

export const enrollInProduct = async (product: SlimProduct): Promise<boolean> => {
  const isBundle = product?.__typename == 'BundleProduct' ?? false
  try {
    const customer = JSON.parse(localStorage.getItem('customer') ?? '{}') as Customer
    if (!customer?.entity_id) {
      getAndSetCustomer()
    }
    if (isBundle) {
      for (const childProduct of product.items) {
        try {
          await sendEnrollment(customer, childProduct as SlimProduct, false)
        } catch (e) {
          console.log(`enrollment failed with error ${e}`)
        }
      }
      const ssoUrl: string = await getRLPSSOToken(getStoreName(), getBearerToken(), '/training/learner/dashboard') ?? ''
      localStorage.setItem('sso_url', ssoUrl ?? '')
    } else {
      try {
        await sendEnrollment(customer, product as SlimProduct)
      } catch (e) {
        console.log(`enrollment failed with error ${e}`)
        return false
      }
    }
    return true
  } catch (e: unknown) {
    return false
  }
  return false
}