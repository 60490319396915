import axios, { AxiosResponse } from 'axios'
import { ref, reactive, computed } from 'vue'
import { getCustomer } from '../../../../global/js/magento-api-library/customer/getCustomer'
import { getAndSetCustomer }from '@/freecme/js/composables/user/checkIfUserLoggedIn'
import gql from '../../../../global/js/utils/gql'
import getStoreName from '../../../../global/js/utils/getStoreName'
import getBearerToken from '../../../../global/js/utils/getBearerToken'
import Customer from '../../../../global/js/magento-api-library/types/Customer'
import checkIfUserLoggedIn from '@/freecme/js/composables/user/checkIfUserLoggedIn'

/**
 * Defines partial transcript data
 */
export interface Transcript {
  progress: number,
  courseCode: string,
}

/**
 * Defines the wishlist data structure that the GQL calls return
 */
interface WishlistResponse {
  id: string
  items_count: number
  name: string
  sharing_code: string
  updated_at: string
  visibility: string
}

/**
 * Defines the GQL response when adding a course to the wishlist
 */
interface AddProductsToWishlistResponse {
  data: {
    addProductsToWishlist: {
      wishlist: WishlistResponse
    }
  }
  errors?: [{ message: string }]
}

/**
 * Defines the GQL response when removing a course from the wishlist
 */
interface RemoveProductsFromWishlistResponse {
  data: {
    removeProductsFromWishlist: {
      wishlist: WishlistResponse
    }
  }
  errors?: [{ message: string }]
}

/**
 * Add courses (products) to the wishlist mutation
 */
const addProductsToWishlistMutation = gql`
  mutation($wishlistId: ID!, $wishlistItems: [WishlistItemInput!]!) {
    addProductsToWishlist(wishlistId: $wishlistId, wishlistItems: $wishlistItems) {
      wishlist {
        id
        items_count
        name
        sharing_code
        updated_at
        visibility
      }
    }
  }
`

/**
 * Remove courses (products) from the wishlist mutation
 */
const removeProductsFromWishlistMutation = gql`
  mutation($wishlistId: ID!, $wishlistItemsIds: [ID!]!) {
    removeProductsFromWishlist(wishlistId: $wishlistId, wishlistItemsIds: $wishlistItemsIds) {
      wishlist {
        id
        items_count
        name
        sharing_code
        updated_at
        visibility
      }
    }
  }
`

const DEFAULT_SAVE_ERROR_MSG = 'An error has occured while saving the course to wishlist. <br /><br />Please try again later.'
const DEFAULT_REMOVE_ERROR_MSG = 'An error has occured while removing the course from wishlist. <br /><br />Please try again later.'

/**
 * Get customer and transcript data from local storage on initial load
 */
const customer = ref<Customer | null>(JSON.parse(localStorage.getItem('customer') ?? '{}') as Customer)
const transcript = JSON.parse(localStorage.getItem('fcme_transcript') ?? '{}')
const transcriptLoading = ref(false)

/**
 * Refresh transcript customer data
 */
const refreshCustomerTranscript = async () => {
  localStorage.removeItem('fcme_transcript')
  transcript.value = []
  await getAndSetCustomer(true)
  transcript.value = JSON.parse(localStorage.getItem('fcme_transcript') ?? '{}')
}

/**
 * Return false if customer data or the fcme_wishlist property doesn't exist
 */
const customerDataReady = (): boolean => {
  if (!customer.value || !customer.value.fcme_wishlist) {
    console.warn('Customer is not signed in or wishlist data is unavailable.')
    return false
  } else {
    return true
  }
}

/**
 * Handles error related data
 */
export const error = reactive({
  hasError: false,        // Error flag
  courseSku: '',         // Course that triggered the error
  uiErrorMessage: '',     // error message meant to be shown to user
  systemErrorMessage: '', // error message meant for logging
})

/**
 * Set the error object back to default values
 */
export const clearError = (): void => {
  setError(false, '', '', '')
}

/**
 * Sets data on the error object
 */
const setError = (hasError: boolean, productSku: string, uiErrorMessage: string, systemErrorMessage: string): void => {
  error.hasError = hasError
  error.courseSku = productSku
  error.uiErrorMessage = uiErrorMessage
  error.systemErrorMessage = systemErrorMessage
}

/**
 * Handles determining if the provided course should be shown in the wishlist
 */
export const showCourseInWishlist = async (sku: string): Promise<boolean> => {
  let showCourse = false

  const courseInWishlist = isCourseInWishlist(sku)          // customer.value?.fcme_wishlist?.items has a record for this SKU

  // If the course is in the wishlist
  if (courseInWishlist) {
    // Show course if it isn't already complete AND isn't expired
    //const courseIsCompleted = await isCourseCompleted(sku)  // transcript.progress == 1
    const courseIsExpired = isCoureExpired(sku)             // course expiration date is not in the past

    if(/*!courseIsCompleted &&*/ !courseIsExpired) { 
      showCourse = true
    }
  }

  return showCourse
}

/**
 * PAP-9313 - Exclude courses that have been completed from showing in the wishlist
 */
export const isCourseCompleted = async (sku: string): Promise<boolean> => {
  let courseIsComplete = false

  // If transcript data is missing, try to load it again
  if (transcript === undefined || Object.entries(transcript).length <= 0 && !transcriptLoading.value) {
    transcriptLoading.value = true
    await refreshCustomerTranscript()
  }

  // If transcript data is found, try to match the given course to find progress
  if (transcript.length > 0) {
    const courseProgress = transcript.find((item: Transcript) => item.courseCode === sku)

    // If the course has transcript data AND the course has been completed
    if (courseProgress && courseProgress.progress == 1) {
      courseIsComplete = true
      removeFromWishlist(sku)
    }
  }  

  return courseIsComplete
}

/**
 * @TODO
 */
const isCoureExpired = (sku: string): boolean | null => {

  const courseExpiration = customer.value?.fcme_wishlist?.items?.find(item => item.sku === sku)?.expiration_date

  if (!courseExpiration) {
    return null
  }

  const dateToCheck = new Date(courseExpiration)
  const currentDate = new Date()

  // Compare the dates: if dateToCheck is in the past, return true
  if(dateToCheck < currentDate) {
    removeFromWishlist(sku)
    return true
  } else {
    return false
  }
}


/**
 * Returns true if the given SKU is in the wishlist item collection
 */
export const isCourseInWishlist = (sku: string): boolean => {
  const courseExists = customer.value?.fcme_wishlist?.items?.find(item => item.sku === sku)

  if (courseExists && courseExists.wishlist_item_id) {
    return true
  } else {
    return false
  }
}

/**
 * Toggles a course in the wishlist: adds it if not present, removes it if already there
 */
export const toggleWishlistCourse = (sku: string): void => {
  isCustomerLoggedIn()

  if (isCourseInWishlist(sku)) {
    removeFromWishlist (sku)
  } else {
    saveToWishlist(sku)
  }
}

/**
 * Save a single course to the wishlist
 */
export const saveToWishlist = async (sku: string): Promise<void> => {
  // Ensure customer is signed in and wishlist exists
  if (!customerDataReady()) { return }

  // Get the wishlist ID
  const wishlistId = customer.value?.fcme_wishlist.id === undefined ? 0 : customer.value?.fcme_wishlist.id

  // Add the course to the wishlist
  await addProductsToWishlist(wishlistId, [{ sku: sku, quantity: 1, selected_options: null}])
}

/**
 * Remove a single course from the wishlist
 */
export const removeFromWishlist = async (sku: string): Promise<void> => {
  // Ensure customer is signed in and wishlist exists
  if (!customerDataReady()) { return }

  // Try to find the course in the wishlist by matching SKUs
  const productWishlistId = customer.value?.fcme_wishlist?.items?.find(item => item.sku === sku)?.wishlist_item_id

  // If the course is part of the wishlist, remove it
  if (productWishlistId !== undefined && customer.value?.fcme_wishlist.id !== undefined) {
    await removeProductsFromWishlist(customer.value?.fcme_wishlist.id, productWishlistId.toString(), sku)
  }
}

/**
 * Remove course(s) from wishlist.
 * Updates customer data in local storage if successful
 */
const removeProductsFromWishlist = async (
  wishlistId: number,
  wishlistItemId: string,
  wishlistItemSku: string,
): Promise<WishlistResponse | null | undefined> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<RemoveProductsFromWishlistResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: removeProductsFromWishlistMutation,
      variables: {
        wishlistId: wishlistId || '',
        wishlistItemsIds: wishlistItemId,
      },
    },
  })

  if (data.errors) {
    setError(true, wishlistItemSku[0], DEFAULT_REMOVE_ERROR_MSG, data.errors[0].message)
    throw new Error(data.errors[0].message)
  }

  if (data.data.removeProductsFromWishlist) {
    updateCustomer()
    return data.data.removeProductsFromWishlist.wishlist
  }

  return null
}

/**
 * Add product(s) to wishlist.
 * Updates customer data in local storage if successful
 */
const addProductsToWishlist = async (
  wishlistId: number,
  wishlistItems: { sku: string, quantity: number, selected_options: string[] | null }[],
): Promise<WishlistResponse | null | undefined> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<AddProductsToWishlistResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: addProductsToWishlistMutation,
      variables: {
        wishlistId: wishlistId || '',
        wishlistItems: wishlistItems.map(item => ({
          sku: item.sku,
          quantity: item.quantity,
          selected_options: item.selected_options ?? null,
        })),
      },
    },
  })

  if (data.errors) {
    setError(true, wishlistItems[0].sku, DEFAULT_SAVE_ERROR_MSG, data.errors[0].message)
    throw new Error(data.errors[0].message)
  }

  if (data.data.addProductsToWishlist) {
    updateCustomer()
    return data.data.addProductsToWishlist.wishlist 
  }

  return null
}

/**
 * Updates customer data in local storage and refreshes the local reference
 */
const updateCustomer = async () => {
  customer.value = await getCustomer()
  localStorage.setItem('customer', JSON.stringify(customer.value))
  customer.value = JSON.parse(localStorage.getItem('customer') ?? '{}') as Customer
}

/**
 * Checks if customer is logged in and if not, redirects to login page.
 */
const isCustomerLoggedIn = (): void => {
  const checkIfLoggedIn = computed(() => checkIfUserLoggedIn())
  if(!checkIfLoggedIn.value) {
    window.location.assign('/user/login')
  }
}