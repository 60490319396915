import axios from 'axios'
import { log } from '../../../../global/js/utils/logging'

const url = '/actions/customrepository-module/customer-assets/add-update-profile'

export const updateProfilePicture = async (profileData:File | null): Promise<void> => {
  const { data }: AxiosResponse<GetCustomerResponse> = await axios({
    method: 'POST',
    url: url,
    headers: {
      'content-type': 'multipart/form-data',
    },
    data: profileData,
  })

  if(data['error']) {
    log(data['message'], 'src/freecme/js/composables/user/userProfilePicture.ts')
  }
}