
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  emits: ['paginate'],
  setup(props, { emit }) {    
    const page = ref<number>(1)

    const updatePage = (updatedPage:number):void => {
      page.value = updatedPage
    }

    const paginate = (updatedValue: number):void => {
      page.value = updatedValue
      emit('paginate', updatedValue)
    }
    
    return {
      page,
      paginate,
      updatePage,
    }
  },

  async updated() {
    this.updatePage(this.currentPage)
  },
})
