const reportError = (errorCase:string) : string => {
  let errorMessage = ''
  switch (errorCase){
    case 'START_ACTIVITY':
      errorMessage = 'We have encountered an issue trying to start your Course. Please refresh and try again. If you are still experiencing issues, please submit your request <a href=\'/contact-us\'>here</a> '
      break
    default:
      break
  }
  return errorMessage
}

export default reportError