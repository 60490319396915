
import { defineComponent } from 'vue'
import isUserLoggedIn from '../../composables/user/checkIfUserLoggedIn'
import logOutUser from '../../composables/user/logout'

export default defineComponent({
  name: 'LoginLogoutLinks',
  computed: {
    checkLoggedIn(): boolean {
      return isUserLoggedIn()
    },
  },
  methods: {
    logOut() {
      logOutUser()
    },
  },
})
