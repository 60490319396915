
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Modal',
  props: {
    showBtnClose: {
      type: Boolean,
      required: true,
    },
    showLoadingAnimation: {
      type: Boolean,
      required: true,
    },
    styleClass:{
      type: String,
      default: '',
      required: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
})
