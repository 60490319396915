
import { defineComponent } from 'vue'
import checkIfUserLoggedIn from '../../composables/user/checkIfUserLoggedIn'

export default defineComponent({
  name: 'CmsBlockCreateAccount',
  props:{
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    isLoggedIn() {
      return checkIfUserLoggedIn()
    },
  },
})
