
import { defineComponent, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'DynamicSelect',
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const selectIsActive = ref<boolean>(false)
    const toggleObject = ref<HTMLElement|null>(null)

    const toggleSelect = (): void => {
      selectIsActive.value = !selectIsActive.value
    }

    return {
      toggleObject,
      toggleSelect,
      selectIsActive,
    }
  },
})
