
import {defineComponent} from 'vue'
export default defineComponent( {
  name: 'Search',
  setup() {
    /**
     * https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/vue/
     * Set up the search input name according to algolia requirements
     */
    const searchInputName = process.env.ELASTICSEARCH_FCME_INDEX +'[query]' ?? ''

    return {
      searchInputName,
    }
  },
})
