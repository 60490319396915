import {resetCart} from '../../../../global/js/utils/cartHandler'

const logOutUser = (): void => {
  localStorage.removeItem('authToken')
  localStorage.removeItem('customer')
  localStorage.removeItem('fcme_transcript')
  resetCart()
  window.location.href = '/user/login?logout=true'
}

export default logOutUser
