
import {defineComponent, reactive, PropType, computed, watch} from 'vue'
import {useField, useForm} from 'vee-validate'
import DropDownComponent from '../form/DropDownComponent.vue'
import Country from '../../../../global/js/magento-api-library/types/Country'
import { errorMessage } from '../../store/modules/ui/errorMessage'
import Vue3Recaptcha from 'vue3-recaptcha2'
export default defineComponent({
  name: 'RegisterForm',
  components: {
    DropDownComponent,
    Vue3Recaptcha,
  },
  props: {
    credentials: {
      type:  Array as PropType<Array<{ value: string, label: string }>>,
      default: () => ['', ''],
    },
    specialties: {
      type:  Array as PropType<Array<{ value: string, label: string }>>,
      default: () => ['', ''],
    },
    professions: {
      type:  Array as PropType<Array<{ value: string, label: string }>>,
      default: () => ['', ''],
    },
    countries: {
      type:  Array as PropType<Array<Country>>,
      default: () => [],
    },
    states: {
      type:  Array as PropType<Array<{ value: string, label: string }>>,
      default: () => [],
    },
    salutations: {
      type:  Array as PropType<Array<{ value: string, label: string }>>,
      default: () => [],
    },
    noneSelected: {
      type: Boolean,
      default: false,
    },
    showStateDropdown: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
    recaptchaV2SiteKey: {
      type: String,
      default: '',
    },
  },
  emits: {
    submitRegister: null,
    setCountry: null,
    v2Response: null,
  },
  setup(props, {emit}) {
    const {meta: formMeta, setFieldValue, setFieldTouched, handleSubmit} = useForm()
    const emailField = reactive(useField('email', {email: true, required: 'Email'}))
    const passwordField = reactive(useField('password', {password: true, required: 'Password'}))
    const confirmPasswordField = reactive(useField('confirmPassword', 'confirmPassword:@password'))
    const salutationField = reactive(useField('salutation'))
    const firstNameField = reactive(useField('firstName', {validName: 'First Name', required: 'First Name'}))
    const middleNameField = reactive(useField('middleName', 'validName'))
    const lastNameField = reactive(useField('lastName', {validName: 'Last Name', required: 'Last Name'}))
    const suffixField = reactive(useField('suffix', {validSuffix: true}))
    const credentialField = reactive(useField('credential', {required: 'Credentials'}))
    const specialtyField = reactive(useField('specialty', {required: 'Specialty'}))
    const professionField = reactive(useField('profession', {required: 'Profession'}))
    const countryField = reactive(useField('country', {required: 'Country'}))


    const stateValidator = computed(() => {
      return props.showStateDropdown ? { required: 'State' } : () => true
    })

    const stateField = reactive(useField('state', stateValidator))
    const postalCodeField = reactive(useField('postalCode', {validPostalCode: true, required: true}))

    const dropdownCountries = computed(() => {
      if (props.countries !== undefined) {
        return [...props.countries].map((country) => {
          return {
            label: country.full_name_english,
            value: country.id,
          }
        })
      }
      return []
    })

    watch(
      () => props.showStateDropdown,
      () => {
        stateField.validate()
      },
    )

    watch(
      () => props.noneSelected,
      () => {
        if(props.noneSelected) {
          setFieldValue('postalCode', '27560')
          setFieldValue('state', {'value':'44','label':'North Carolina'})
        } else {
          setFieldValue('postalCode', '')
          setFieldValue('state', '')
        }
      },
    )

    function setCountry(item: { value: string, label: string }) {
      setFieldValue('country', item)
      emit('setCountry', item)
    }

    const handleRecaptchaV2Response = () => {
      emit('v2Response', true)
    }

    const submitForm = handleSubmit((formValues) => {
      emit('submitRegister', {
        email: formValues.email,
        password: formValues.password,
        prefix: (formValues.salutation === undefined) ? '-' : formValues.salutation.value,
        firstname: formValues.firstName,
        middlename: formValues.middleName,
        lastname: formValues.lastName,
        suffix: formValues.suffix,
        freecme_credentials: formValues.credential.value,
        freecme_specialty: formValues.specialty.value,
        freecme_profession: formValues.profession.value,
        country: formValues.country.value,
        state: (formValues.state?.label === undefined) ? '' : formValues.state.label,
        stateCode: (formValues.state?.value === undefined) ? '' : formValues.state.value,
        postalCode: formValues.postalCode,
      })
    },
    ({ errors }) => {
      const firstError = Object.keys(errors)[0]
      const el = document.querySelector(`[name="${firstError}"]`)
      el?.scrollIntoView({
        behavior: 'smooth',
      })
      if (el !== null) {
        (el as HTMLElement).focus()
      }
    })

    return {
      emailField,
      // eslint-disable-next-line vue/no-dupe-keys
      errorMessage,
      passwordField,
      confirmPasswordField,
      salutationField,
      firstNameField,
      middleNameField,
      lastNameField,
      suffixField,
      credentialField,
      specialtyField,
      professionField,
      countryField,
      stateField,
      postalCodeField,
      dropdownCountries,
      submitForm,
      formMeta,
      setFieldValue,
      setFieldTouched,
      setCountry,
      handleRecaptchaV2Response,
    }
  },
})
