
import { defineComponent } from 'vue'
import { passwordReset } from '../../../../global/js/magento-api-library/auth/passwordReset'
import loading from '../../composables/loaders/loading'
import ResetPasswordForm from './ResetPasswordForm.vue'
import {login} from '@/global/js/magento-api-library/auth/login'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    ResetPasswordForm,
  },
  props:{
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      displayErrorMessage: {
        label: '',
        value: false,
      },
      errorMessage: '',
    }
  },
  methods: {
    async resetPassword(formValues: Record<string, string>) {
      loading(true)
      const searchParams = new URLSearchParams(location.search)
      const resetToken = searchParams.get('reset_token')
      const userEmail = searchParams.get('user_email')?.replace(/ /g, '+')
            
      if(resetToken && userEmail) {
        const successfullReset =  await passwordReset(userEmail, resetToken, formValues.password)

        if(successfullReset === true) {
          const successfulLogin = await login(userEmail, formValues.password)
          loading(false)
          if(successfulLogin) {
            window.location.href = '/user/reset-password?reset_password=success'
          }
          else {
            window.location.href = '/user/login'
          }
        } else {
          loading(false)
          if(typeof successfullReset == 'string') {
            this.displayErrorMessage = {
              label: successfullReset,
              value: true,
            }
          }
        }
      }
    },
  },
})
