import { computed, ComputedRef } from 'vue'

import { useStore } from '../../store/index'
import { UIMutationTypes } from '../../store/modules/ui/mutation-types'

interface mobileNavigation {
  mobileNavIsOpen: ComputedRef<boolean>
  toggleMobileNav: () => void
}

const useMobileNav = (): mobileNavigation => {
  const store = useStore()

  const mobileNavIsOpen = computed((): boolean => store.state.ui.mobileNavIsOpen)

  const toggleMobileNav = (): void => {
    store.commit(UIMutationTypes.TOGGLE_MOBILE_NAV)

    const documentBody = document.querySelector('body')
    const pageWrapper = document.querySelector('.page-wrapper')
    const pageOverlay = document.querySelector('#page-overlay')

    documentBody?.classList.toggle('has-overlay')
    pageWrapper?.classList.toggle('mobile-menu-active')
    pageOverlay?.classList.toggle('active')
  }

  return {
    mobileNavIsOpen,
    toggleMobileNav,
  }
}

export default useMobileNav
