
import { defineComponent, reactive } from 'vue'
import { useField, useForm } from 'vee-validate'
import { errorMessage } from '../../store/modules/ui/errorMessage'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: 'ResetPasswordForm',
  components: {FontAwesomeIcon},
  props:{
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const {meta: formMeta, handleSubmit} = useForm()
    const passwordField = reactive(useField('password', {password: true, required: true}))
    const confirmPasswordField = reactive(useField('confirmPassword', 'confirmPassword:@password'))
    const searchParams = new URLSearchParams(location.search)
    let resetPasswordSuccess = false
    if(searchParams.get('reset_password') === 'success') {
      resetPasswordSuccess = true
    }

    const submitForm = handleSubmit(async (formValues) => {
      emit('submitReset', {
        password: formValues.password,
      })
    })

    return {
      passwordField,
      confirmPasswordField,
      submitForm, 
      formMeta,
      errorMessage,
      resetPasswordSuccess,
    }
  },
})
