
import {defineComponent, onMounted, reactive, ref} from 'vue'
import { useField, useForm } from 'vee-validate'
import { errorMessage } from '../../store/modules/ui/errorMessage'
import { passwordResetEmail } from '../../../../global/js/magento-api-library/auth/passwordResetEmail'
import loading from '../../composables/loaders/loading'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import checkIfUserLoggedIn, {getAndSetCustomer} from "@/freecme/js/composables/user/checkIfUserLoggedIn";

export default defineComponent( {
  name: 'RetrievePassword',
  components:{
    FontAwesomeIcon,
  },
  props:{
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { meta: formMeta, handleSubmit } = useForm()
    const emailField = reactive(useField('email', { email: true, required: true}))
    const displayAlert = ref<boolean>(false)
    let error = true
    const formComplete = ref<boolean>(false)

    onMounted(async (): Promise<void> => {
      if (checkIfUserLoggedIn()) {
        window.location.href = '/'
      }
    })

    const submitField = handleSubmit(async (formValues) => {
      loading(true)
      const successfullReset = await passwordResetEmail(formValues.email)
      loading(false)
      displayAlert.value = true
      if (successfullReset) {
        formComplete.value = true
        error = false
      }
    })

    return {
      formMeta,
      submitField,
      emailField,
      errorMessage,
      displayAlert,
      error,
      formComplete,
    }
  },
})
