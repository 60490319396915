
import { defineComponent } from 'vue'
import useMobileNav from '../../composables/navigation/mobileNavigation'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'MobileNav',
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const { mobileNavIsOpen, toggleMobileNav } = useMobileNav()

    return {
      mobileNavIsOpen,
      toggleMobileNav,
    }
  },
  mounted() {
    const pageOverlay = document.querySelector('#page-overlay')
    pageOverlay?.addEventListener('click', this.toggleMobileNav)
  },
})
