import { MutationTree } from 'vuex'

import { State } from './state'
import { CourseMutationTypes } from './mutation-types'

export interface Mutations {
  [CourseMutationTypes.setSortOption]: (state: State, sortOption: string) => void
  [CourseMutationTypes.setSortOrder]: (state: State, sortOrder: string) => void
}

export const mutations: MutationTree<State> & Mutations = {
  [CourseMutationTypes.setSortOption]: (state: State, sortOption: string) => {
    state.sortOption = sortOption
  },

  [CourseMutationTypes.setSortOrder]: (state: State, sortOrder: string) => {
    state.sortOrder = sortOrder
  },
}
