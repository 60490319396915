import {onMounted, ref, onBeforeUnmount, Ref} from 'vue'

interface UseMobileViewport {
  isMobileViewport: Ref<boolean>
}

const useMobileViewport = (): UseMobileViewport => {
  const isMobileViewport = ref<boolean>(false)

  const checkIfMobileViewport = (): void => {
    isMobileViewport.value = window.innerWidth < 768
  }

  onMounted(() => {
    checkIfMobileViewport()
    window.addEventListener('resize', checkIfMobileViewport)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkIfMobileViewport)
  })

  return {
    isMobileViewport,
  }
}

export default useMobileViewport