/**
 * ABTasty Documentation:
 * https://developers.abtasty.com/docs/tag/tag-getting-started
 */
import { ref, watch } from 'vue'
import { log } from './logging'

// Toggles abTastyDebug mode on/off
export const abDebug = ref(false)

// Script IDs used to load ABTasty
const FCME_ABTASTY_TAG_ID = process.env.ABTASTY_FCME
const RM_ABTASTY_TAG_ID = process.env.ABTASTY_RM

/**
 * When enabled, the ABTasty debug mode will post 
 * debugging data to the browser's console.
 */
window.abTastyDebug = abDebug.value

/**
 * Adds ABTasty script to the DOM if it isn't already initialized
 */
export const loadABTastyScript = (site: string):Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (typeof window.abtasty !== 'undefined' && typeof window.abtasty.send === 'function') {
      resolve()
    } else {
      const tagId = site === 'fcme' ? FCME_ABTASTY_TAG_ID : RM_ABTASTY_TAG_ID
      const script = document.createElement('script')
      script.src = '//try.abtasty.com/'+tagId+'.js'
      script.async = true
      script.onload = () => resolve()
      script.onerror = () => reject(new Error('Failed to load AB Tasty script'))
      document.head.appendChild(script)
    }
  })
}

/**
 * Sends custom event data to ABTasty
 * 
 * @param siteID - "fcme" | "rm"
 */
export const abCustomEvent = async (siteID:string, eventData: Record<any, any>):Promise<void> => {
  // Stop the event from firing on environments that don't use ABTasty
  if (typeof FCME_ABTASTY_TAG_ID === 'undefined' || typeof RM_ABTASTY_TAG_ID === 'undefined') {
    return
  }

  try {
    await loadABTastyScript(siteID)

    // Confirm ABTasty has loaded and then trigger the custom event tracking
    if (typeof window.abtasty !== 'undefined' && typeof window.abtasty.send === 'function') {
      window.abtasty.send('event', {
        name: eventData.name, // Custom Event Name
        ec: eventData.ec, // Event Category
        ea: eventData.ea, // Event Action
      })
    } else {
      log('AB Tasty is not ready, cannot send the event.', 'src/global/js/utils/abTasty.ts')
    }
  } catch (error) {
    log('Failed to load AB Tasty script: ' + error, 'src/global/js/utils/abTasty.ts')
  }
}

// Updates the ABTasty debug mode
watch(abDebug, (newValue) => {
  window.abTastyDebug = newValue
}) 