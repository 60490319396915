interface useProductFlag {
  formatDate: (time:number|string) => string,
  checkExpiration: (expires:number|string) => boolean,
  convertStringToInt: (time: string) => string | number,
}

const useProductFlags = (): useProductFlag => {
  /**
   * Formats the time to [Mon XX XXXX] (Month Day Year)
   * 
   * The epoch timestamp requires you to multiply the epoch time by 1000 to work with the Data javascript library
   * https://stackoverflow.com/a/12342913
   */
  const formatDate = (time: number | string): string => {
    let timeVal = time
    
    // If time is a string
    if (typeof timeVal === 'string') {
      if (/^\d+$/.test(timeVal)) {
        // If the string contains only digits, treat it as a UNIX timestamp
        timeVal = Number(timeVal)
      } else {
        // Otherwise, treat it as a formatted date string
        const date = new Date(timeVal.replace(' ', 'T'))
        timeVal = Math.floor(date.getTime() / 1000)
      }
    }
  
    // Ensure the value is a valid number
    if (timeVal != null && !isNaN(Number(timeVal))) {
      // Convert UNIX timestamp to a human-readable date
      const formattedDate = new Date(Number(timeVal) * 1000)
      return formattedDate.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    } else {
      return ''
    }
  }

  /**
   * @TODO Description
   */
  const checkExpiration = (expires: number | string): boolean => {
    let expiresVal = expires

    if (typeof expiresVal === 'string'){
      expiresVal = parseInt(expiresVal)
    }

    if (expiresVal != null) {
      const expirationDate = new Date(expiresVal * 1000)
      expirationDate.setMonth(expirationDate.getMonth() - 1)
      const today = new Date()

      const diff = today.getTime() - expirationDate.getTime()
      return diff >= 0
    } else {
      return false
    }
  }

  const convertStringToInt = (time: string): string | number => {
    //Attempt to convert the string to an float check for the false positive "0.5" or "12343"
    const unixTimestamp = parseFloat(time, 10)

    //If the string was successfully converted to a float just return that
    if (!isNaN(unixTimestamp)) {
      return unixTimestamp
    } else {
      return time
    }
  }

  return {
    formatDate,
    checkExpiration,
    convertStringToInt,
  }
}

export default useProductFlags