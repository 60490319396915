
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    showCloseBtn: {
      default: false,
      type: Boolean,
    },
    classes: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
      required: true,
    },
  },

  setup() {
    const toggleParent = ref<HTMLElement>()

    const togglePanel = (title: string | undefined): void => {
      const parent = toggleParent.value
      parent?.classList.toggle('active')
      /* Update the aria-expanded value */
      const panelContent = parent?.querySelector('.panel-content')
      if (parent?.classList.contains('active') && panelContent) {
        panelContent.setAttribute('aria-expanded', 'true')
      } else {
        panelContent.setAttribute('aria-expanded', 'false')
      }
      /* Update the title to display: X Close */
      const panelTitle = parent?.querySelector('.panel-title')
      if (parent?.classList.contains('active') && panelTitle) {
        panelTitle.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="14" width="9.625" viewBox="0 0 352 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.2-22.2c-12.3-12.3-32.2-12.3-44.5 0L176 189.3 75.9 89.2c-12.3-12.3-32.2-12.3-44.5 0L9.2 111.5c-12.3 12.3-12.3 32.2 0 44.5L109.3 256 9.2 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 322.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 256z"/></svg> Close`
      }else{
        panelTitle.textContent = title || ''
      }
    }

    const replaceSpacesAndSpecialCharacters = (value: string): string => {
      // Replace spaces with underscores
      let replacedValue = value.replace(/\s/g, '_')

      // Remove special characters
      replacedValue = replacedValue.replace(/[^\w\s]/gi, '')

      return replacedValue
    }


    return {
      togglePanel,
      toggleParent,
      replaceSpacesAndSpecialCharacters,
    }
  },
})
