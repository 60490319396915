import { Module } from 'vuex'

import { RootState } from '../../index'

import { state, State } from './state'
import { mutations } from './mutations'

export type { State }

export const store: Module<State, RootState> = {
  state,
  mutations,
}
