import axios, { AxiosResponse } from 'axios'

import getStoreName from '../utils/getStoreName'
import getBearerToken from '../utils/getBearerToken'
import type Customer from '../magento-api-library/types/Customer'
import getRLPSSOToken from '../utils/getRLPSSOToken'
import Product from '../magento-api-library/types/Product'
export type ChildProducts = {sku: string, rlms_id: string}[]
export type SlimProduct = {
  'sku': string,
  'rlms_id': string,
  '__typename': string,
  'is_curriculum'?: boolean,
  items: ChildProducts,
}

export type enrollEndpointResponse = {
  errors?: [{message: string}],
  string: unknown,
  nextExamUrl?: string,
}
export const sendEnrollment = async (
  customer: Customer,
  product: SlimProduct,
  returnLink = true,
): Promise<string | unknown> => {
  // TODO: Find a 'simple' HTTP method where we can still use auth - n8n does not respond to OPTIONS with allowed headers header making this tricky
  // const bearerToken = process.env.MIX_N8N_HOOK_KEY
  const enrollEndpoint = `${process.env.MIX_N8N_HOST}${process.env.MIX_N8N_RLP_ENROLLMENT_URI}`
  const headers = {
    'Content-Type': 'text/plain',
    // Authorization: `Bearer ${bearerToken}`,
  }
  const expirationDate = new Date()
  expirationDate.setFullYear(expirationDate.getFullYear() + 2)
  const isoExpirationDate = expirationDate.toISOString()

  const {data}: AxiosResponse<enrollEndpointResponse> = await axios({
    method: 'POST',
    url: enrollEndpoint,
    headers,
    data: {
      store: getStoreName(),
      PartnerSlug: null,
      CourseSlug: product.rlms_id ?? product.sku,
      FirstName: customer.firstname,
      LastName: customer.lastname,
      ExternalUserID: customer.entity_id,
      ExternalRegistrationID: 'FCME_enrollment',
      ExpirationDate: isoExpirationDate,
      UserName: customer.email,
      EmailAddress: customer.email,
      RegistrationType: product.is_curriculum ? 1 : 2, //program/course respectively
      DontOverwriteEmptyStrings: true,
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.nextExamUrl && returnLink) {
    const sso_link: string = await getRLPSSOToken(getStoreName(), getBearerToken(), data.nextExamUrl as string) ?? ''
    localStorage.setItem('sso_url', sso_link ?? '')
    return true
  }

  return null
}
