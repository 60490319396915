
export default {
  mounted(): void {
    const navItems = document.querySelectorAll('nav ul li a')
    const backToTop = document.getElementsByClassName('back-to-top')
    const backToTopStart = 250
    let section = null
    let fromTop = 0

    window.addEventListener('scroll', () => {
      fromTop = window.scrollY

      if (fromTop > backToTopStart) {
        backToTop[0].classList.add('active')
      } else {
        backToTop[0].classList.remove('active')
      }

      navItems.forEach((link) => {
        const navItem = link as HTMLAnchorElement
        section = document.querySelector(navItem.hash) as HTMLElement

        if (
          section?.offsetTop <= fromTop &&
          section?.offsetTop + section.offsetHeight > fromTop
        ) {
          navItem.classList.add('active')
        } else {
          navItem.classList.remove('active')
        }
      })
    })
  },
}
