import {onBeforeUnmount, onMounted, Ref} from 'vue'

const useClickOutside = (elementRef: Ref, callbackFun: () => void): void => {

  const checkIfClickedOutside = (e: Event) => {
    if (!e.composedPath().includes(elementRef.value)) {
      if (typeof callbackFun == 'function') {
        callbackFun()
      }
    }
  }

  onMounted(() => {
    document.addEventListener('click', checkIfClickedOutside)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('click', checkIfClickedOutside)
  })
}

export default useClickOutside