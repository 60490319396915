
import { defineComponent, ref } from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default defineComponent({
  components:{
    FontAwesomeIcon,
  },
  props: {
    showCloseBtn: {
      default: false,
      type: Boolean,
    },
    classes: {
      default: '',
      type: String,
    },
  },

  setup() {
    const toggleParent = ref<HTMLElement>()

    const closeAllPanels = (): void => {
      const parent = toggleParent.value
      const closestParent = parent?.closest('.accordion')

      if (closestParent) {
        const accordions = closestParent?.closest('.accordion')
        const panels = accordions?.querySelectorAll('.panel')

        panels?.forEach((element) => {
          element.classList.remove('active')
        })
      }
    }

    const togglePanel = (): void => {
      const parent = toggleParent.value
      parent?.classList.toggle('active')
    }

    return {
      closeAllPanels,
      togglePanel,
      toggleParent,
    }
  },
})
