import { MutationTree } from 'vuex'

import { State } from './state'
import { UIMutationTypes } from './mutation-types'

export interface Mutations {
  [UIMutationTypes.TOGGLE_MOBILE_NAV]: (state: State) => void
}

export const mutations: MutationTree<State> & Mutations = {
  [UIMutationTypes.TOGGLE_MOBILE_NAV]: (state: State) => {
    state.mobileNavIsOpen = !state.mobileNavIsOpen
  },
}
